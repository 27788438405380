import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/4wheelupgrades/Templates/SearchPage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/_default-store/Templates/fitmentSearch/singleVehicleGarage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/4wheelupgrades/Templates/fitmentSearch/verifyFitment.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SingleVehicleGarage,
VehicleWidget,
SearchHeader,
SearchResult,
FacetPanel,
SearchBoxDialogButton,
ContextDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};